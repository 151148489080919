<template>
    <div>
        <div class="meta-props">
            <span><span class="prefix">Fábrica:</span> {{ brasindiceProps.lab }}</span>&nbsp;
            <span v-if="brasindiceProps.cd_tuss"><span class="prefix">TUSS:</span> {{ brasindiceProps.cd_tuss }}</span>
        </div>
        <div class="item">{{ brasindiceProps.item }} {{ brasindiceProps.apres }}</div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        brasindiceProps: {
            type: Object,
            required: true
        }
    },
}
</script>
<style lang="scss" scoped>
.meta-props {
    font-size: 0.6rem;
    .prefix {
        max-width: 20%;
        font-weight: 400;
        color: var(--neutral-500);
        cursor: pointer;
    }
}
.item {
    font-size: 0.8rem;
}
</style>